import React, {useEffect, useState} from "react";
import {ITrack} from "../../models/track";
import {useParams, useRouteMatch} from "react-router-dom";
import {Lyrics} from "./Lyrics";
import {YouTubeVideoContainer} from "./YouTubeVideoContainer";
import {useHistory} from "react-router";
import {fetchTrack} from "../../stores/tracks";
import {LoadingSpinner} from "../Layout/LoadingSpinner";
import {CloseIcon, SocialYouTubeIcon} from "../Layout/Icons";
import "./style.css"

interface ITrackProps {
    onMount(): void;
    onUnmount(): void;
}

interface ITrackParams {
    playlist_id: string;
    track_id: string;
}

export const Track = ({onMount, onUnmount}: ITrackProps) => {
    const history = useHistory();
    const {playlist_id, track_id} = useParams() as ITrackParams;
    const match = useRouteMatch();

    const [track, setTrack] = useState<ITrack>();
    const [loading, setLoading] = useState<boolean>(true);

    const handleClose = () => {
        history.push(match.path.replace(':playlist_id', playlist_id).replace(/^(.*)\/[^/]+$/, '$1'));
    };

    useEffect(() => onMount(), [onMount]);
    useEffect(() => () => onUnmount(), [onUnmount]);

    useEffect(() => {
        (async () => {
            setLoading(true);

            const track = await fetchTrack(track_id);

            setTrack(track);
            setLoading(false);
        })();
    }, [track_id]);

    if (loading) {
        return (
            <div className="details-box loading">
                <LoadingSpinner />
            </div>
        )
    }

    if (!track) {
        return null;
    }

    return (
        <>
            <div className="details-box">
                <div className="details-box-header">
                    <h2 className="video-title">
                        <SocialYouTubeIcon />
                        {track.title}
                    </h2>

                    <CloseIcon className="close-icon" onClose={handleClose} />
                </div>

                <YouTubeVideoContainer track={track} onChange={(track) => setTrack(track)} />
            </div>

            {!!track.lyrics && <Lyrics lyrics={track.lyrics} />}
        </>
    );
};
