import React from "react";
import {ITrack} from "../../models/track";
import {YouTubeVideo} from "./YouTubeVideo";
import {useTranslation} from "react-i18next";
import {NavLink, Route, Switch, useRouteMatch} from "react-router-dom";

interface IMultipleYouTubeVideosProps {
    track: ITrack;
}

export const MultipleYouTubeVideos = ({track}: IMultipleYouTubeVideosProps) => {
    const {t} = useTranslation();
    const match = useRouteMatch();

    return (
        <>
            <ul className="track-additional-videos">
                <li>
                    <NavLink exact to={match.url}>{t('section.track.main_video')}</NavLink>
                </li>
                {track.videos.map((item, i) => (
                    <li key={i}>
                        <NavLink to={`${match.url}/video-${i + 1}`}>
                            {item.title || `Video #${i + 1}`}
                        </NavLink>
                    </li>
                ))}
            </ul>

            <div className="video-box">
                <Switch>
                    {track.videos.map((item, i) => (
                        <Route exact path={`${match.path}/video-${i + 1}`} key={i}>
                            <YouTubeVideo youtubeId={item.youtube_id} />
                        </Route>
                    ))}
                    <Route exact path={match.path}>
                        <YouTubeVideo youtubeId={track.youtube_id!!} />
                    </Route>
                </Switch>
            </div>
        </>
    );
};
