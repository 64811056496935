import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import {en} from "../translations/en";

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources: {
            en: {
                translation: en,
            }
        },
        lng: "en",
        fallbackLng: "en",

        interpolation: {
            escapeValue: false
        },
        keySeparator: false
    });

export default i18n;
