import React from "react";

interface ICloseIconProps {
    className?: string;
    onClose(): void;
}

export const CloseIcon = ({className = "", onClose = () => {}}: ICloseIconProps) => (
    <i className={`icon ion-ios7-close-outline ${className}`} onClick={onClose} />
);
