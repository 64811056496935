import React from "react";

interface IRedoIconProps {
    className?: string;
    onClick(): void;
}

export const RedoIcon = ({className = "", onClick = () => {}}: IRedoIconProps) => (
    <i className={`icon ion-ios7-redo-outline ${className}`} onClick={onClick} />
);
