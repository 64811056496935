export const openSignInWindow = (url: string, name: string, listener: any): Window | null => {
    window.removeEventListener('message', listener);

    // window features
    const popupWidth = 550;
    const popupHeight = 550;
    const popupTop = ((window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight) / 2) - (popupHeight / 2);
    const popupLeft = ((window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth) / 2) - (popupWidth / 2);
    const strWindowFeatures = `toolbar=no, menubar=no, width=${popupWidth}, height=${popupHeight}, top=${popupTop}, left=${popupLeft}`;

    const windowObjectReference = window.open(url, name, strWindowFeatures);

    window.addEventListener('message', event => listener(event), false);

    return windowObjectReference;
};
