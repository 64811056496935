import React from "react";
import {ITrack} from "../../models/track";
import {SubmitVideo} from "./SubmitVideo";
import {YouTubeVideo} from "./YouTubeVideo";
import {MultipleYouTubeVideos} from "./MultipleYouTubeVideos";

interface IVideoContainerProps {
    track: ITrack;
    onChange(track: ITrack): void;
}

export const YouTubeVideoContainer = ({track, onChange}: IVideoContainerProps) => {
    if (!track.youtube_id) {
        return (
            <div className="video-box">
                <SubmitVideo track={track} onChange={onChange} />
            </div>
        );
    }

    if (!!track.videos.length) {
        return (
            <MultipleYouTubeVideos track={track} />
        );
    }

    return (
        <div className="video-box">
            <YouTubeVideo youtubeId={track.youtube_id} />
        </div>
    );
};
