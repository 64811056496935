import React, {useState} from "react";
import {Link, NavLink} from "react-router-dom";
import {MenuPlaylists} from "./MenuPlaylists";
import {IPlaylist} from "../../../models/playlist";
import {useTranslation} from "react-i18next";
import {ShareToSocialMenuItems} from "./ShareToSocialMenuItems";
import {GearIcon} from "../Icons";
import "./style.css"

interface IMenuProps {
    loading: boolean;
    playlists: IPlaylist[];
}

export const Menu = ({loading, playlists}: IMenuProps) => {
    const {t} = useTranslation();

    const [isMenuVisible, setIsMenuVisible] = useState<boolean>(false);

    const toggleMenuVisibility = () => {
        setIsMenuVisible(!isMenuVisible);
    }

    return (
        <nav className={`navigation ${isMenuVisible ? "show-menu" : ""}`} role="navigation">
            <h1 className="brand"><Link to="/">SpotyTube</Link></h1>

            <span onClick={toggleMenuVisibility} className={`icon ion-navicon-round show-menu ${isMenuVisible ? "active" : ""}`} />

            <ul className="icons">
                <li><NavLink to="/settings"><GearIcon /></NavLink></li>
                <ShareToSocialMenuItems />
            </ul>

            <h5 className="playlists-title">{t('section.playlist.title')}</h5>
            <div className="playlists-container">
                <MenuPlaylists loading={loading} playlists={playlists} onClick={() => setIsMenuVisible(false)} />
            </div>
        </nav>
    );
};
