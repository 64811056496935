import {action, computed, makeAutoObservable, observable} from "mobx";
import JwtHelper from "../utils/jwt";
import {fetchGetBase} from "../services/api";

const TOKEN_CACHE_KEY = 'spotytube:user:access_token';

export class AuthStore {
    @observable accessToken: string | null = null;

    constructor() {
        makeAutoObservable(this);

        this.accessToken = AuthStore.getStoredAccessToken();
    }

    static getStoredAccessToken(): string | null {
        return window.localStorage.getItem(TOKEN_CACHE_KEY) || null;
    }

    @action
    setAccessToken(accessToken: string) {
        window.localStorage.setItem(TOKEN_CACHE_KEY, accessToken);

        this.accessToken = accessToken;
    }

    @action
    clear() {
        window.localStorage.removeItem(TOKEN_CACHE_KEY);

        this.accessToken = null
    }

    @computed get isAccessTokenValid(): boolean {
        if (!this.accessToken) {
            return false;
        }

        const jwtHelper = new JwtHelper();

        try {
            const token = jwtHelper.decodeToken(this.accessToken);

            const tokenExp = new Date()
            tokenExp.setTime(token.exp * 1000);

            return tokenExp > new Date();
        } catch (error) {
            return false;
        }
    }
}

export const authorize = (): Promise<string> => {
    return fetchGetBase( '/authorize', {redirect: false})
        .then(data => {
            return data.data.redirect_url;
        });
};
