import React, {useState} from "react";
import {RedoIcon, SocialFacebookIcon, SocialTwitterIcon} from "../Icons";

const SOCIAL_URLS = {
    facebook: 'http://www.facebook.com/share.php?u=https://spotytu.be&title=View your Spotify music on SpotyTube',
    twitter: 'http://twitter.com/home?status=View your @spotify music on SpotyTube https://spotytu.be',
};

export const ShareToSocialMenuItems = () => {
    const [isShareButtonsVisible, setShareButtonsVisibility] = useState<boolean>(false);

    const toggleShareButtonsVisibility = () => {
        setShareButtonsVisibility(!isShareButtonsVisible);
    }

    const handleShare = (social: "facebook" | "twitter") => () => {
        window.open(
            SOCIAL_URLS[social],
            `Share on ${social}`,
            'menubar=no,location=no,resizable=no,scrollbars=no,status=no, width=' + 400 + ', height=' + 400 + ', top=' + 100 + ', left=' + 100
        )
    };

    return (
        <>
            <li><RedoIcon onClick={toggleShareButtonsVisibility} className={`share-button ${isShareButtonsVisible ? "active" : ""}`} /></li>
            {isShareButtonsVisible &&
                <>
                    <li className="share-button"><SocialFacebookIcon onClick={handleShare('facebook')} /></li>
                    <li className="share-button"><SocialTwitterIcon onClick={handleShare('twitter')} /></li>
                </>
            }
        </>
    );
};
