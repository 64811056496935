import React from "react";
import {useTranslation} from "react-i18next";
import {SadIcon} from "../Layout/Icons";

export const NoTracks = () => {
    const {t} = useTranslation();

    return (
        <div className="sorry no-tracks">
            {t('section.playlist.empty_playlist')}
            <SadIcon />
        </div>
    );
};
