export const en = {
    'slogan': 'View Your Music',
    'welcome.enter': 'Enter',
    'welcome.access_denied': 'Access Denied!',
    'welcome.retry': 'retry',
    'welcome.login': 'Login',
    'welcome.login_via': 'via spotify',
    'welcome.login_popup_window.title': 'Login with Spotify',
    'login.footer.title': 'Syncing your music! 🥳 🔊',
    'login.footer.wait_message': 'This operation my take few minutes, don\'t close this window.',
    'background.photo_by': 'Photo by',
    'section.about.title': 'About',
    'section.contacts.title': 'Contacts',
    'welcome_user': 'Welcome {{name}}',
    'fetch_data.label': 'Re-sync with Spotify your plyalists and tracks',
    'fetch_data.playlists': '<span class="count">{{created}}</span> created, <span class="count">{{updated}}</span> updated',
    'fetch_data.tracks': '<span class="count">{{created}}</span> created',
    'section.playlist.title': 'Playlists',
    'section.playlist.tracks_count': '{0} no songs|{1} just one song|[2,Inf[ %count% songs',
    'section.playlist.tracks_count_0': 'no songs',
    'section.playlist.tracks_count_1': 'just one song',
    'section.playlist.tracks_count_inf': '%count% songs',
    'section.playlist.no_playlists': 'You haven\'t created any playlist yet',
    'section.playlist.search_placeholder': 'Search your playlist',
    'section.playlist.empty_playlist': 'No tracks in this playlist!',
    'section.playlist.header.track': 'Track',
    'section.playlist.header.artist': 'Artist',
    'section.playlist.header.time': 'Time',
    'section.tracks.header.title': 'Tracks',
    'section.tracks.header.filter_count': '{{filtered_count}} of {{all_count}}',
    'section.track.main_video': 'Main video',
    'section.track.no_video': 'Video not available',
    'section.track.no_video_reasons_title': 'This error may be due to the following reasons:',
    'section.track.no_video_reasons_1': 'The video cannot be embedded',
    'section.track.no_video_reasons_2': 'The video hasn\'t been found on YouTube',
    'section.track.no_video_reasons_3': 'We haven\'t been able to find the best video',
    'section.track.provide_video_url': 'Do you have an official music video for this song? Please, report it in the form below! Thank you :)',
    'section.track.provide_video.error.title': 'An error occurred: {{error}}',
    'section.track.provide_video.error.unknown': 'Unknown error',
    'section.track.provide_video.error.invalid_url': 'please provide a valid YouTube URL!',
    'section.track.provide_video.error.non_existing_video': 'the video doesn\'t seem to exist',
    'section.track.provide_video.error.non_matching_video': 'the provided URL doesn\'t seem to match',
    'section.track.youtube_url_label': 'YouTube URL',
    'section.track.track_not_found': 'Track not found',
    'youtube.error.video_not_matching': 'The YouTube video provided doesn\'t seem to match with the track',
    'youtube.error.url_not_valid': 'The URL provided doesn\'t seem to be a valid YouTube URL',
    'section.lyrics.title': 'Lyrics',
    'section.settings.title': 'Account',
    'youtube.error.video_not_found': 'The YouTube video provided doesn\'t exist'
};
