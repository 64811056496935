import * as React from 'react'
import {TrackStore} from "../stores/tracks";
import {AuthStore} from "../stores/auth";
import {QuoteStore} from "../stores/quotes";
import {UserStore} from "../stores/users";
import {SyncStore} from "../stores/sync";

interface IGlobalStores {
    authStore: AuthStore,
    syncStore: SyncStore,
    trackStore: TrackStore,
    quoteStore: QuoteStore,
    userStore: UserStore,
}

const globalStores = {
    authStore: new AuthStore(),
    syncStore: new SyncStore(),
    trackStore: new TrackStore(),
    quoteStore: new QuoteStore(),
    userStore: new UserStore(),
};

export const createGlobalStores = () => {
    return globalStores;
};

export const GlobalStoresContext = React.createContext<IGlobalStores>(globalStores);
