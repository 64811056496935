import React from 'react';
import {BrowserRouter, Route, Switch} from "react-router-dom";
import {OuterContainer} from "./Layout/OuterContainer";
import {Welcome} from "./Welcome/Welcome";
import {SpotyTubeContainer} from "./Layout/SpotyTubeContainer";
import {Settings} from "./Settings/Settings";
import {Home} from "./Home/Home";
import {Tracks} from "./Tracks/Tracks";
import {observer} from "mobx-react-lite";
import {useGlobalStores} from "../hooks/use_global_stores";
import {Login} from "./Login/Login";

export const App = observer(() => {
    const {authStore} = useGlobalStores();

    if (!authStore.isAccessTokenValid) {
        return (
            <OuterContainer showCredits={true}>
                <BrowserRouter>
                    <Switch>
                        <Route path="/login" exact>
                            <Login />
                        </Route>
                        <Route path="/" exact>
                            <Welcome />
                        </Route>
                    </Switch>
                </BrowserRouter>
            </OuterContainer>
        );
    }

    return (
        <OuterContainer>
            <BrowserRouter>
                <Switch>
                    <SpotyTubeContainer>
                        <Route path="/settings" exact>
                            <Settings />
                        </Route>
                        <Route path="/playlists/:playlist_id/tracks">
                            <Tracks />
                        </Route>
                        <Route path="/" exact>
                            <Home />
                        </Route>
                    </SpotyTubeContainer>
                </Switch>
            </BrowserRouter>
        </OuterContainer>
    );
});
