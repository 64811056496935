import React, {useRef} from "react";
import {useTranslation} from "react-i18next";
import "./style.css"

interface ISyncProps {
    hasFooter?: boolean;
    loading?: 'pre-auth' | 'sync';
}

export const Sync = ({hasFooter, loading}: ISyncProps) => {
    const {t} = useTranslation();

    const loadingBarRef = useRef<HTMLDivElement>(null);

    if (!!loading) {
        setTimeout(() => {
            if (!loadingBarRef.current) {
                return;
            }

            if (loading === 'pre-auth') {
                loadingBarRef.current!!.className += ' loading-bar-pre-auth';
            } else if (loading === 'sync') {
                setTimeout(function() {
                    loadingBarRef.current!!.style.width = '15%';
                }, 100);

                setTimeout(function() {
                    loadingBarRef.current!!.style.width = '35%';
                }, 2000);

                setTimeout(function() {
                    loadingBarRef.current!!.style.transition = 'width 10s';
                    loadingBarRef.current!!.style.width = '100%';
                }, 2500);
            }
        }, 10);
    }

    return (
        <div className="sync-outer-container">
            <div className="sync-container">
                <div><i className="icon ion-ios7-reloading loading" /></div>

                {!!hasFooter &&
                    <div className="sync-footer">
                        <div className="syncing-message">{t('login.footer.title')}</div>
                        <div className="wait-message">{t('login.footer.wait_message')}</div>

                        <div className="loading-bar-container">
                            <div ref={loadingBarRef} id="loading-bar" className="loading-bar" />
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};
