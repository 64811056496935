import React, {useEffect} from "react";
import {observer} from "mobx-react";
import {authorize} from "../../stores/auth";
import {Sync} from "../Sync/Sync";

export const Login = observer(() => {
    useEffect(() => {
        authorize().then(redirectUrl => {
            window.location.href = redirectUrl;
        });
    }, []);

    return (
        <Sync loading="pre-auth" />
    );
});
