import {action, makeAutoObservable, observable} from "mobx";
import {fetchPost} from "../services/api";
import {IPlaylistsResync} from "../models/playlist";

export class SyncStore {
    @observable shouldBeSynced: boolean = false;

    constructor() {
        makeAutoObservable(this);
    }

    @action
    mustSync() {
        this.shouldBeSynced = true;
    }

    @action
    synced() {
        this.shouldBeSynced = false;
    }
}

export const sync = (): Promise<IPlaylistsResync> => {
    return fetchPost('/sync')
        .then(data => {
            return data.data;
        })
};
