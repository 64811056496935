import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {LoadingSpinner} from "../Layout/LoadingSpinner";

interface IFilterTracksFormProps {
    loading: boolean;
    onChange: Function;
}

export const FilterTracksForm = ({loading, onChange}: IFilterTracksFormProps) => {
    const {t} = useTranslation();

    const [query, setQuery] = useState<string>('');

    const handleChange = (event: any) => {
        setQuery(event.target.value);

        onChange(event.target.value);
    };

    return (
        <form className={`tracks-search-form ${loading ? 'loading' : ''}`}>
            <input
                type="text"
                value={query}
                onChange={handleChange}
                placeholder={t('section.playlist.search_placeholder')}
            />

            {loading && <LoadingSpinner/>}
        </form>
    );
};
