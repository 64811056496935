import {IQuote} from "../models/quote";

export class QuoteStore {
    private quotes: IQuote[] = [
        {text: 'I\'d rather be dead than cool.', author: 'Kurt Kobain'},
        {text: 'Darling, my attitude is "fuck it"; I\'m doing everything with everyone.', author: 'Freddie Mercury'},
        {text: 'We believed that anything that was worth doing was worth overdoing.', author: 'Steve Tyler'},
    ];

    getRandomQuote(): IQuote {
        return this.quotes[Math.floor(Math.random() * this.quotes.length)];
    }
}
