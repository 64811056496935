import React from 'react';
import ReactDOM from 'react-dom';
import './config/i18n'
import {App} from "./components/App";
import './styles.css'

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
