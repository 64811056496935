import React from "react";
import {useTranslation} from "react-i18next";
import {SadIcon} from "../Icons";

export const NoPlaylists = () => {
    const {t} = useTranslation();

    return (
        <ul className="list">
            <li className="no-playlists">
                {t('section.playlist.no_playlists')}<br />
                <SadIcon />
            </li>
        </ul>
    );
}
