import React from "react";
import {useTranslation} from "react-i18next";

interface ILyricsProps {
    lyrics: string;
}

export const Lyrics = ({lyrics}: ILyricsProps) => {
    const {t} = useTranslation();

    return (
        <>
            <h2 className="title"><span>{t('section.lyrics.title')}</span></h2>
            <div className="lyrics-box">
                <p>
                    {lyrics}
                </p>
            </div>
        </>
    );
};
