export const debounce = <F>(func: Function, waitFor: number) => {
    let timeout: number | any = 0;

    const debounced = (...args: any[]) => {
        clearTimeout(timeout);
        timeout = setTimeout(() => func(...args), waitFor)
    };

    return debounced as (...args: any[]) => F;
};
