import React from "react";
import {PageBackground} from "./PageBackground";
import {useTranslation} from "react-i18next";
import {getBrowserSpecificCssClassName} from "../../utils/browser";

interface IRootContainerProps {
    showCredits?: boolean;
    children: React.ReactNode;
}

export const OuterContainer = ({children, showCredits = false}: IRootContainerProps) => {
    const {t} = useTranslation();

    return (
        <>
            <PageBackground />

            <div className={`container ${getBrowserSpecificCssClassName()}`}>
                {children}

                {showCredits &&
                    <div className="page-background-author">
                        {t('background.photo_by')} <a href="https://www.flickr.com/photos/onepointfour/" target="_blank" rel="noreferrer">Dustin Gaffke</a>
                    </div>
                }
            </div>
        </>
    );
};
