import {fetchGet} from "../services/api";
import {IUser} from "../models/user";
import {action, computed, makeAutoObservable, observable} from "mobx";

export class UserStore {
    @observable user: IUser | null = null;

    constructor() {
        makeAutoObservable(this);
    }

    @action
    setUser(user: IUser) {
        this.user = user;
    }

    @computed get isUserValid(): boolean {
        return !!this.user;
    }
}

export const fetchEgo = (): Promise<IUser> => {
    return fetchGet( `/users/me`)
        .then(data => {
            return data.data;
        });
};
