import React from "react";
import {useTranslation} from "react-i18next";
import {ITrack} from "../../models/track";
import {TrackItem} from "./TrackItem";

interface ITracksListProps {
    tracks: ITrack[];
}

export const TracksList = ({tracks}: ITracksListProps) => {
    const {t} = useTranslation();

    return (
        <>
            <div className="tracks">
                <div className="header track-item">
                    <div className="cell first action" />
                    <div className="cell track">{t('section.playlist.header.track')}</div>
                    <div className="cell artist">{t('section.playlist.header.artist')}</div>
                    <div className="cell last duration">{t('section.playlist.header.time')}</div>
                </div>
            </div>

            <div className="tracks-container">
                <div className="tracks">
                    {tracks.map(track => <TrackItem track={track} key={track.id} />)}
                </div>
            </div>
        </>
    );
};
