import React, {useState} from "react";
import {LoadingSpinner} from "../Layout/LoadingSpinner";
import {useTranslation} from "react-i18next";
import {openSignInWindow} from "../../services/auth-popup";
import {API_HOST} from "../../config/config";
import {useGlobalStores} from "../../hooks/use_global_stores";
import {SadIcon} from "../Layout/Icons";

export const Login = () => {
    const {authStore, syncStore} = useGlobalStores();
    const {t} = useTranslation();

    const [denied, setDenied] = useState<boolean>();
    const [loading, setLoading] = useState<boolean>(false);

    const callbackListener = (event: any) => {
        if (event.origin !== API_HOST) {
            return;
        }

        const { data } = event;

        setLoading(false);
        if (data === 'access_denied') {
            setDenied(true);
        } else {
            authStore.setAccessToken(event.data);
            syncStore.mustSync();

            setDenied(false);
        }
    };

    const handleLogin = (event: any) => {
        event.preventDefault();

        setLoading(true);

        openSignInWindow(`/login`, t('welcome.login_popup_window.title'), callbackListener);
    };

    if (loading) {
        return (
            <div className="login">
                <LoadingSpinner />
            </div>
        );
    }

    if (denied) {
        return (
            <div className="login">
                <div className="access-denied">
                    <div className="error-message">
                        <SadIcon /> {t('welcome.access_denied')}
                    </div>

                    <span className="login-button retry" onClick={handleLogin}>{t('welcome.retry')}</span>
                </div>
            </div>
        );
    }

    return (
        <div className="login">
            {/* This is need in order to load the icon beforehand */}
            <div className="login-loading-container"><LoadingSpinner /></div>

            <span className="login-button" onClick={handleLogin}>{t('welcome.login')}</span>
            <span className="login-via">{t('welcome.login_via')}</span>
        </div>
    );
};
