import React from "react";

interface IYouTubeVideoProps {
    youtubeId: string;
}

export const YouTubeVideo = ({youtubeId}: IYouTubeVideoProps) => (
    <iframe
        title="Video"
        src={`https://www.youtube.com/embed/${youtubeId}`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
    />
);
