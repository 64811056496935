export const getBrowserSpecificCssClassName = (): string => {
    if (window.navigator.userAgent.indexOf('MSIE') > -1) {
        return 'b-ie';
    } else if (window.navigator.userAgent.indexOf('Firefox') > -1) {
        return 'b-firefox';
    } else if (window.navigator.userAgent.indexOf('Chrome') > -1) {
        return 'b-chrome';
    } else if (window.navigator.userAgent.indexOf('Safari') > -1) {
        return 'b-safari';
    } else if (window.navigator.userAgent.indexOf('Presto') > -1) {
        return 'b-opera';
    }

    return '';
};
