import {AuthStore} from "../stores/auth";
import {API_HOST, API_V1_BASE} from "../config/config";

export const fetchGetBase = (url: string, query: any = {}, props: any = {}): Promise<any> => {
    return fetch(API_HOST + url + '?' + new URLSearchParams(query), {
        mode: 'cors',
        redirect: 'manual',
        ...props
    })
        .then(response => response.json());
};

export const fetchGet = (url: string, query: any = {}, props: any = {}): Promise<any> => {
    return fetch(API_V1_BASE + url + '?' + new URLSearchParams(query), {
        mode: 'cors',
        headers: {
            'Authorization': `Bearer ${AuthStore.getStoredAccessToken()}`,
        },
        ...props
    })
        .then(response => response.json());
};

export const fetchPost = (url: string, data: any = {}): Promise<any> => {
    return fetch(API_V1_BASE + url, {
        mode: 'cors',
        headers: {
            'Authorization': `Bearer ${AuthStore.getStoredAccessToken()}`,
            'Content-Type': 'application/json',
        } as any,
        method: 'POST',
        body: JSON.stringify(data),
    })
        .then(response => response.json());
};
