import React from "react";
import {useTranslation} from "react-i18next";
import {Login} from "./Login";
import {PlusIcon} from "../Layout/Icons";
import {SpotifyLogo, YouTubeLogo} from "./SocialLogos";
import "./style.css"
import {Sync} from "../Sync/Sync";

export const Welcome = () => {
    const {t} = useTranslation();

    return (
        <div className="welcome-box-container">
            <div className="welcome-box">
                <div className="logo">
                    <span className="name">SpotyTube</span>
                    <span className="slogan">{t('slogan')}</span>
                </div>

                <div className="social-logos">
                    <SpotifyLogo />
                    <PlusIcon className="plus" />
                    <YouTubeLogo />
                </div>

                <Login />
            </div>
        </div>
    );
};
