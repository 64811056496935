import React from "react";
import {ITrack} from "../../models/track";
import {NavLink, useRouteMatch} from "react-router-dom";
import {convertMillisecondsToReadableFormat} from "../../utils/time";
import {PlayIcon} from "../Layout/Icons";

interface ITrackItemProps {
    track: ITrack;
}

export const TrackItem = ({track}: ITrackItemProps) => {
    const match = useRouteMatch();

    return (
        <NavLink to={`${match.url}/${track.id}`} className="track-item">
            <div className="cell first action"><PlayIcon /></div>
            <div className="cell track">{track.title}</div>
            <div className="cell artist">{track.artist}</div>
            <div className="cell last duration">{convertMillisecondsToReadableFormat(track.duration)}</div>
        </NavLink>
    );
};
