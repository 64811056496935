import React from "react";
import {useTranslation} from "react-i18next";
import {observer} from "mobx-react";
import {useGlobalStores} from "../../hooks/use_global_stores";
import {Resync} from "./Resync";

export const Settings = observer(() => {
    const {userStore} = useGlobalStores();
    const {t} = useTranslation();

    return (
        <div className="first-content-box settings">
            <h2 className="title"><span>{t('section.settings.title')}</span></h2>
            <h4>{t('welcome_user', {name: userStore.user?.username})}<span className="smile">!</span></h4>
            <div className="clear" />

            <Resync />
        </div>
    );
});
