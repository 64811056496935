import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {ITrack} from "../../models/track";
import {updateTrackYouTubeId} from "../../stores/tracks";
import {LoadingSpinner} from "../Layout/LoadingSpinner";
import {SadIcon} from "../Layout/Icons";

interface IVideoContainerProps {
    track: ITrack;
    onChange(track: ITrack): void;
}

export const SubmitVideo = ({track, onChange}: IVideoContainerProps) => {
    const {t} = useTranslation();

    const [url, setUrl] = useState<string>('');
    const [error, setError] = useState<"unknown" | "invalid_url" | "non_existing_video" | "non_matching_video" | "error" | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const handleUrlChange = (event: any) => {
        setUrl(event.target.value);
    };

    const handleSubmit = (event: any) => {
        event.preventDefault();

        if (loading) {
            return;
        }

        if (url.trim() === '') {
            return;
        }

        setError(null);
        setLoading(true);

        updateTrackYouTubeId(track.id, url)
            .then(track => {
                onChange(track);
            })
            .catch(reason => {
                setLoading(false);

                switch (reason.data.error.exception_class) {
                    case 'YouTubeUrlNonValidException': setError('invalid_url'); break;
                    case 'YouTubeVideoNotFoundException': setError('non_existing_video'); break;
                    case 'YouTubeVideoNotMatchingException': setError('non_matching_video'); break;
                    default: setError('unknown');
                }
            });
    };

    return (
        <>
            <div className="sorry no-video">
                {t('section.track.no_video')}
                <SadIcon />
            </div>

            <div className="reasons">
                {t('section.track.no_video_reasons_title')}
                <ul>
                    <li>{t('section.track.no_video_reasons_1')}</li>
                    <li>{t('section.track.no_video_reasons_2')}</li>
                    <li>{t('section.track.no_video_reasons_3')}</li>
                </ul>

                <div className="provide-url">
                    {t('section.track.provide_video_url')}
                    <br />
                    <form className={`${loading ? "loading" : ""}`} onSubmit={handleSubmit}>
                        <input
                            type="url"
                            className="url"
                            placeholder={t('section.track.youtube_url_label')}
                            value={url}
                            onChange={handleUrlChange}
                            disabled={loading}
                        />

                        {loading ?
                            <div className="button"><LoadingSpinner /></div> :
                            <input className="button" type="submit" value="Send" onClick={handleSubmit} />}
                        <div className="clear" />
                    </form>

                    {!!error && <div className="provide-url-error">{t('section.track.provide_video.error.title', {error: t(`section.track.provide_video.error.${error}`)})}</div>}
                </div>
            </div>
        </>
    );
};
