import React from "react";
import {NavLink} from "react-router-dom";
import {NoPlaylists} from "./NoPlaylists";
import {LoadingSpinner} from "../LoadingSpinner";
import {IPlaylist} from "../../../models/playlist";

interface IMenuPlaylistsProps {
    loading: boolean;
    playlists: IPlaylist[];
    onClick(): void;
}

export const MenuPlaylists = ({loading, playlists, onClick}: IMenuPlaylistsProps) => {
    if (loading) {
        return (
            <LoadingSpinner />
        );
    }

    if (!playlists.length) {
        return (
            <NoPlaylists />
        );
    }

    return (
        <ul className="list">
            {
                playlists.map(playlist => (
                    <li key={playlist.id}>
                        <NavLink to={`/playlists/${playlist.id}/tracks`} onClick={onClick}>
                            <span className="playlist-name">{playlist.name}</span>
                            <span className="badge">{playlist.tracks_count}</span>
                        </NavLink>
                    </li>
                ))
            }
        </ul>
    );
};
