import React, {useEffect, useState} from "react";
import {Menu} from "./Menu/Menu";
import {IPlaylist} from "../../models/playlist";
import {fetchPlaylists} from "../../stores/playlists";
import {LoadingSpinner} from "./LoadingSpinner";
import {fetchEgo} from "../../stores/users";
import {useGlobalStores} from "../../hooks/use_global_stores";
import {sync} from "../../stores/sync";
import {Sync} from "../Sync/Sync";

interface ISpotyTubeContainerProps {
    children: React.ReactNode;
}

export const SpotyTubeContainer = ({children}: ISpotyTubeContainerProps) => {
    const {syncStore, userStore} = useGlobalStores();
    const [playlists, setPlaylists] = useState<IPlaylist[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [syncing, setSyncing] = useState<boolean>(false);

    useEffect(() => {
        (async () => {
            if (!syncStore.shouldBeSynced) {
                setLoading(true);

                const [user, playlists] = await Promise.all([
                    fetchEgo(),
                    fetchPlaylists(),
                ]);

                userStore.setUser(user);

                setPlaylists(playlists);
                setLoading(false);
            } else {
                if (syncing) {
                    return;
                }

                setSyncing(true);

                await sync();

                syncStore.synced();

                setSyncing(false);
            }
        })();
    }, [setLoading, setPlaylists, userStore, syncStore.shouldBeSynced]);

    if (loading) {
        return (
            <div className="overlay-loading">
                <LoadingSpinner />
            </div>
        );
    }

    if (syncing) {
        return (
            <Sync hasFooter loading="sync" />
        );
    }

    return (
        <>
            <Menu loading={false} playlists={playlists} />

            <div className="main-box">
                {children}
            </div>
        </>
    );
};
