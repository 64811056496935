import React, {useEffect, useState} from "react";
import {useGlobalStores} from "../../../hooks/use_global_stores";
import {IQuote} from "../../../models/quote";

export const Quote = () => {
    const {quoteStore} = useGlobalStores();

    const [quote, setQuote] = useState<IQuote>();

    useEffect(() => {
        setQuote(quoteStore.getRandomQuote());
    }, [quoteStore, setQuote]);

    if (!quote) {
        return null;
    }

    return (
        <div className="quote-container">
            <div className="quote-box">
                <div className="quote">
                    <h4>"{quote.text}"</h4>
                    <span>{quote.author}</span>
                </div>
            </div>
        </div>
    );
};
