import {ITrack} from "../models/track";
import {fetchGet, fetchPost} from "../services/api";

interface IPlaylistsTracks {
    [key: string]: ITrack[];
}

export class TrackStore {
    private playlistsTracks = {} as IPlaylistsTracks;

    registerPlaylistTracks(playlistId: string, tracks: ITrack[]) {
        this.playlistsTracks[playlistId] = tracks;
    }

    hasPlaylistTracks(playlistId: string): boolean {
        return this.playlistsTracks[playlistId] !== undefined
    }

    loadPlaylistTracks(playlistId: string): ITrack[] {
        if (!this.hasPlaylistTracks(playlistId)) {
            return [];
        }

        return this.playlistsTracks[playlistId];
    }

    clearPlaylistsTracks() {
        this.playlistsTracks = {};
    }
}

export const fetchTracks = (playlist: string, query?: string): Promise<ITrack[]> => {
    const requestQuery = {} as any;
    if (!!query) {
        requestQuery.query = query;
    }

    return fetchGet( `/playlists/${playlist}/tracks`, requestQuery)
        .then(data => {
            return data.data || [];
        });
};

export const fetchTrack = (id: string): Promise<ITrack> => {
    return fetchGet( `/tracks/${id}`)
        .then(data => {
            return data.data;
        });
};

export const updateTrackYouTubeId = (trackId: number, url: string): Promise<ITrack> => {
    return fetchPost(`/tracks/${trackId}/update_video_id`, {youtube_url: url})
        .then(data => {
            if (data.status_code !== 200) {
                return Promise.reject(data);
            }

            return data.data;
        })
};
