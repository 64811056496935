import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {ITrack} from "../../models/track";
import {FilterTracksForm} from "./FilterTracksForm";
import {TracksList} from "./TracksList";
import {NoTracks} from "./NoTracks";
import {Route, Switch, useParams, useRouteMatch} from "react-router-dom";
import {Track} from "../Track/Track";
import {fetchTracks} from "../../stores/tracks";
import {LoadingSpinner} from "../Layout/LoadingSpinner";
import {useGlobalStores} from "../../hooks/use_global_stores";
import {debounce} from "../../utils/debounce";
import "./style.css"

interface ITracksParams {
    playlist_id: string;
}

export const Tracks = () => {
    const {t} = useTranslation();
    const {playlist_id} = useParams() as ITracksParams;
    const match = useRouteMatch();

    // const history = useHistory();
    // const location = useLocation();
    // const [inputQuery, setInputQuery] = useState<string>((new URLSearchParams(location.search)).get('query') || '');

    const {trackStore} = useGlobalStores();

    const [loading, setLoading] = useState<boolean>(false);
    const [tracks, setTracks] = useState<ITrack[]>([]);
    const [filteredTracks, setFilteredTracks] = useState<ITrack[]>([]);
    const [filtered, setFiltered] = useState<boolean>(false);
    const [filterLoading, setFilterLoading] = useState<boolean>(false);
    const [isTrackOpen, setIsTrackOpen] = useState<boolean>(false);

    useEffect(() => {
        if (trackStore.hasPlaylistTracks(playlist_id)) {
            setTracks(trackStore.loadPlaylistTracks(playlist_id));
            setFilteredTracks(trackStore.loadPlaylistTracks(playlist_id));
            return;
        }

        (async () => {
            setLoading(true);

            const tracks = await fetchTracks(playlist_id);
            trackStore.registerPlaylistTracks(playlist_id, tracks);

            setTracks(tracks);
            setFilteredTracks(tracks);
            setLoading(false);
        })();
    }, [playlist_id, trackStore]);

    const filterTracksDebounced = debounce((query: string) => {
        // history.push(`?query=${query}`);

        fetchTracks(playlist_id, query).then(result => {
            setFilterLoading(false);
            setFilteredTracks(result);
        });
    }, 300);

    const handleFilterChange = (query: string) => {
        if (!filterLoading) {
            setFilterLoading(true);
            setFiltered(true);
        }

        if (query.trim() === '') {
            setFilterLoading(false);
            setFiltered(false);
            setFilteredTracks(trackStore.loadPlaylistTracks(playlist_id));

            return;
        }

        filterTracksDebounced(query);
    };

    if (loading) {
        return (
            <div className="first-content-box loading">
                <LoadingSpinner />
            </div>
        );
    }

    return (
        <>
            <div className={`first-content-box tracks-box ${isTrackOpen ? "with-details" : ''}`}>
                <div className="tracks-box-header">
                    <h2 className="title">
                        <span>{t('section.tracks.header.title')}</span>
                        <span className="tracks-count">
                            {!filtered ? tracks.length : t('section.tracks.header.filter_count', {filtered_count: filteredTracks.length, all_count: tracks.length})}
                        </span>
                    </h2>

                    {!!tracks.length && <FilterTracksForm loading={filterLoading} onChange={handleFilterChange} />}
                </div>

                {tracks.length ? <TracksList tracks={filteredTracks} /> : <NoTracks />}
            </div>

            <Switch>
                <Route path={`${match.path}/:track_id`}>
                    <Track onMount={() => setIsTrackOpen(true)} onUnmount={() => setIsTrackOpen(false)} />
                </Route>
            </Switch>

            <div className="clear" />
        </>
    );
};
