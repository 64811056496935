import React, {useState} from "react";
import {LoadingSpinner} from "../Layout/LoadingSpinner";
import {useTranslation} from "react-i18next";
import {observer} from "mobx-react";
import {IPlaylistsResync} from "../../models/playlist";
import {useGlobalStores} from "../../hooks/use_global_stores";
import {ReloadIcon, ReloadingIcon} from "../Layout/Icons";
import {sync} from "../../stores/sync";

export const Resync = observer(() => {
    const {trackStore} = useGlobalStores();
    const {t} = useTranslation();

    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<IPlaylistsResync>();

    const resyncData = () => {
        setLoading(true);

        sync().then(result => {
            trackStore.clearPlaylistsTracks();

            setLoading(false);
            setData(result);
        });
    };

    return (
        <>
            <button className="fetch-data" onClick={resyncData}>
                {loading ? <ReloadingIcon /> : <ReloadIcon />}
                <span>{t('fetch_data.label')}</span>
            </button>

            {data &&
                <ul className="fetch-results">
                    <li dangerouslySetInnerHTML={{__html: `Playlists: ${t('fetch_data.playlists', {created: data.new_playlists, updated: data.updated_playlists})}`}} />
                    <li dangerouslySetInnerHTML={{__html: `Tracks: ${t('fetch_data.tracks', {created: data.new_tracks})}`}} />
                </ul>
            }

            {loading && <div className="overlay-loading"><LoadingSpinner /></div>}
        </>
    );
});
